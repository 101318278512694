import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as printJS from 'print-js';
import Swal from 'sweetalert2';

import { BulkUploadAddComponent } from '../shared/dialogs/bulk-upload-add/bulk-upload-add.component';
import { environment } from 'src/environments/environment';
import { FavAddComponent } from '../shared/dialogs/fav-add/fav-add.component';
import { FavShipModel, ShipmentSaveModel } from 'src/app/models/shipment.model';
import { FavShowComponent } from '../shared/dialogs/fav-show/fav-show.component';
import { PickupsService } from 'src/app/services/pickups.service';
import { ShipmentsHelpComponent } from '../shared/dialogs/shipments-help/shipments-help.component';
import { ShipmentsPrintComponent } from '../shared/dialogs/shipments-print/shipments-print.component';
import { ShipmentsShowComponent } from '../shared/dialogs/shipments-show/shipments-show.component';
import { UsersService } from 'src/app/services/users.service';
import { ViewPickupEvidenceComponent } from '../shared/dialogs/view-pickup-evidence/view-pickup-evidence.component';


@Component({
  selector: 'app-inhouse-shipments',
  templateUrl: './inhouse-shipments.component.html',
  styleUrls: ['./inhouse-shipments.component.scss']
})
export class InhouseShipmentsComponent implements OnInit {
  base64!:string;
  dataSource!: MatTableDataSource<ShipmentSaveModel>;
  displayedColumns: string[] = ['tracking_number', 'carrier', 'destination', 'shipment', 'order_price', 'user_name', 'status','created_at', 'actions'];
  fsDataSource!: MatTableDataSource<FavShipModel>;
  fsDisplayedColumns: string[] = ['alias', 'carrier', 'origin', 'destination', 'shipment',  'receipt'];//['carrier','receiver', 'destination', 'sender','origin', 'shipment','actions'];
  isClientUsr: boolean;
  isLoading: boolean = true;
  isOperationsUsr: boolean;
  shipmentList: Array<ShipmentSaveModel> = new Array<ShipmentSaveModel>();
  statusMapping: any = {
    'assigned': { label:'Recolectado', color: 'badge-orange', icon: 'forklift' },
    'order_created': {label:'Orden recibida', color: 'badge-purple', icon: 'assignment'},
    'order_completed': {label: 'Orden surtida', color: 'badge-dark', icon: 'assignment_turned_in'},
    'started': {label:'¡En ruta!', color: 'badge-warning', icon: 'local_shipping'},
    'successful': {label: 'Entregado', color: 'badge-success', icon: 'done_all'},
    'error': {label:'Error', color: 'badge-warning', icon: 'warning' },
    'pending': {label:'Pendiente', color: 'badge-warning', icon: 'warning' },
    'undelivered': {label:'No entregado', color: 'badge-danger', icon: 'warning' }
  };
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(private matDialog: MatDialog, private location: Location, private pickupS: PickupsService, private router: Router, private userS: UsersService) {
    this.isClientUsr = (this.userS.getUser().id_rol == environment.role_client_id);
    this.isOperationsUsr = (this.userS.getUser().id_rol == environment.role_operations_id);
  }

  get favData(): any { return this.userS.sharedData; };
  get bulkUploadData(): any { return this.userS.sharedBulkUpload; };
  set favData(value: any) { this.userS.sharedData = value; };
  set bulkUploadData(value: any) { this.userS.sharedBulkUpload = value; }

  ngOnInit(): void {
    //if (!this.allowedCompanies()) this.location.back();
    this.getShipmentList();
    this.userS.sendCreditEvent();
  }

  allowedCompanies(): boolean {
    const reqCompaniesShipmentId= [1, 34];
    return (reqCompaniesShipmentId.includes(this.userS.getUser().id_company));
  }

  getShipmentList() {
    this.userS.getInhouseShipmentList().subscribe(
      (result : any) => {
        this.shipmentList = result;
        this.shipmentList = this.shipmentList.map(shipment => {
          return {
            ...shipment,
            to_address: shipment.to_address + ', ' + shipment.dest_country
          };
        });
        for ( let i = 0; i < this.shipmentList.length; i++ ) {
          this.shipmentList[i].packages = JSON.parse(this.shipmentList[i].packages);
        }
        this.dataSource = new MatTableDataSource(this.shipmentList);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  openDialogSaveFav(id: number) {
    const dialogRef = this.matDialog.open( FavAddComponent, {
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      data: { 
        shipmentIdSelected: id
      },
      panelClass: 'custom-dialog-container-scroll',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.goToShipmentFav();
    });
  }

  cleanGoToShipmentAdd() {
    this.favData = null;
    this.goToShipmentAdd();
  }

  openBulkUpload() {
    const dialogRef = this.matDialog.open( BulkUploadAddComponent, {
      data: { 
        //shipmentIdSelected: id
      },
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      //panelClass: 'dialogs-main',
    });

    dialogRef.afterClosed().subscribe(resp => {
      let idBU=0;
      if (resp) {
        //console.log(resp);
        idBU = resp.id
      }
      if(idBU>0)
        this.goToBulkUploads(idBU);
    });
  }

  addFavtoService(obj: FavShipModel) {
    this.favData = obj;
    this.goToShipmentAdd();
  }

  showFav(obj: FavShipModel) {
    this.matDialog.open( FavShowComponent, {
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      data: obj,
      panelClass: 'custom-dialog-container-scroll',
    });
  }


  removeFavAddress(obj: FavShipModel) {
    Swal.fire({
      title: '¿Estás seguro que deseas eliminar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Eliminando...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => { Swal.showLoading(null); }
        });
        this.userS.deleteFavAddress(obj.id).subscribe(
          (result:any) => {
            if (result.message == "Success")                
            Swal.fire({
              icon: 'success',
              title: 'Eliminado',
              text: 'Favorito eliminado de manera correcta!'
            }).then(() => {
              this.goToShipmentFav();
            });
          },
          (err:any) => {
            Swal.fire({
              icon: 'error',
              title: 'Error al eliminar',
              text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
            });
        });
    }}); 
  }

  goToShipmentAdd() {
    this.router.navigateByUrl('/admin/shipments/add');
  }

  goToShipmentFav() {
    this.router.navigateByUrl('/admin/myfavorites');
  }

  goToBulkUploads(id:number) {
    this.bulkUploadData = {"id":id};    
    this.router.navigateByUrl('/admin/bulk-upload');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator)
      this.dataSource.paginator.firstPage();
  }

  trackLabel(id: number) {
    const dialogRef = this.matDialog.open(ShipmentsShowComponent, {
      height: '40rem',
      width:'50rem',
      data: { },
      panelClass: 'custom-dialog-container'
    });
  }

  viewLabel(id: number, isBol: boolean = false) {
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => { Swal.showLoading( null ); }
    });
    this.userS.findLabel(id).subscribe(
      (resp: any) => {
        if ( resp.data.status.code == "success" ) {
          const dialogRef = this.matDialog.open( ShipmentsPrintComponent, {
            height: '40rem',
            width:'80rem',
            data: { 
              data: isBol?resp.data.data.commercial_invoice : resp.data.data.payload
            },
            // panelClass: 'dialogs-main'
            panelClass: 'custom-dialog-container'
          });
          Swal.close();
      }},
      (err: any) => {
        console.log(err);
        Swal.fire({
          icon:  'error',
          title: 'Error',
          text:  'Ocurrió un error al descargar la guía, intenta de nuevo mas tarde.'
        });
    });
  }

  showPickupEvidence(id: string) {
    this.matDialog.open(ViewPickupEvidenceComponent, {
      data: { id: id },
      panelClass: 'dialogs-main'
    });
  }

  printLabel(id:number, isBol:boolean = false) {
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.findLabel(id).subscribe(
      ( resp: any ) => {
        if ( resp.data.status.code == "success" ) {
          printJS({ printable: isBol? resp.data.data.commercial_invoice : resp.data.data.payload, type: "pdf", base64: true })
          Swal.close();
        }
      },
      ( err:any ) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error',
          text:  'Ocurrió un error al descargar la guía, intenta de nuevo mas tarde.'
        });
    });
  }

  goToTracking(tracking_number: string) {
    window.open("https://tracking.buhologistics.com/?id="+tracking_number, "_blank");
  }

  shipmentHelp(obj: any) {
    const dialogRef = this.matDialog.open( ShipmentsHelpComponent, {
      data: { shipmentSel: obj },
      panelClass: 'dialogs-main'
    });
  }

  favCarrierName(obj: any) {
    if (obj != null) return obj.carrier;
    else return '';
  }

  imgFormat(value: string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }
}