import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-quick-quote',
  templateUrl: './quick-quote.component.html',
  styleUrls: ['./quick-quote.component.scss']
})
export class QuickQuoteComponent implements OnInit {
  destAddress!: any;
  inProgress: boolean = false;
  originAddress!: any;
  overflown: boolean = false;
  quotesh!: { carriers: Array<string>, costs: Array<string>, servicetypes: Array<string> };
  quotesv!: Array<{ carrier: string, cost: string, servicetype: string }>;
  qqForm!: FormGroup;
  requestGenerated: boolean = false;
  requestSuccessful: boolean = false;
  selectedIndex: number = -1;
  @ViewChild('hrtable') hrTable!: ElementRef;


  constructor(private cdr: ChangeDetectorRef, private formBuilder: FormBuilder, private router: Router, private user: UsersService) {
    this.quotesh = { carriers: [], costs: [], servicetypes: [] }
    this.quotesv = [];
  }

  ngOnInit(): void { 
    // Create necessary elements
    this.buildAddressObj();
    this.createQQForm();
  }


  /* void createQQForm(0):
     Creates the Quick Quote Form used for the component's process. */
  createQQForm(): void {
    this.qqForm = this.formBuilder.group({
      destPC: ['', [Validators.required, this.PostCodeValidator]],
      height: ['', [Validators.required, Validators.min(1), Validators.pattern(/^[1-9][0-9]*$/)]],
      length: ['', [Validators.required, Validators.min(1), Validators.pattern(/^[1-9][0-9]*$/)]],
      originPC: ['', [Validators.required, this.PostCodeValidator]],
      weight: ['', [Validators.required, Validators.min(1), Validators.pattern(/^[1-9][0-9]*$/)]],
      width: ['', [Validators.required, Validators.min(1), Validators.pattern(/^[1-9][0-9]*$/)]]
    });
  }

  /* string imgFormat(1):
     Gets the picture related to the carrier name.
     (Imported from shipments-add.component)
     < x: The carrier's name */
  imgFormat(x: string) {
    return x.replace(/\s+/g, '').toLowerCase();
  }

  /* boolean invalidForm(0):
     Checks if the form is invalid for the submit button to be deactivated. */
  invalidForm(): boolean {
    return !this.qqForm.valid;
  }

  /* void makeOfficial(0):
     Sends dummy data to the pipe to be used in Shipments Add component. */
  makeOfficial(): void {
    const data = {
      destination: this.qqForm.controls.destPC.value,
      height: this.qqForm.controls.height.value,
      length: this.qqForm.controls.length.value,
      origin: this.qqForm.controls.originPC.value,
      quote: {
        carrier: this.quotesv[this.selectedIndex].carrier,
        cost: parseFloat(this.quotesv[this.selectedIndex].cost.replace(/[$,]/g, '')),
        servicetype: this.quotesv[this.selectedIndex].servicetype,
      } || null,
      weight: this.qqForm.controls.weight.value,
      width: this.qqForm.controls.width.value
    };

    this.user.sendNewShipData(data);
    this.router.navigate(['admin/shipments/add']);
  }

  /* void select(1):
     Sets selectedIndex to the index of the user's click to mark a whole
     element of the quotes' table as "selected".
     < index: Position of the element that triggered the function. */
  select(index: number): void {
    this.selectedIndex = index;
  }

  /* boolean selected(1):
     Defines the addition of the "selected" class in the tables' implementation.
     < index: Position of the element that triggered the function. */
  selected(index: number): boolean {
    return (index === this.selectedIndex);
  }

  /* boolean tooLong(1):
     Checks if the content is long enough to need a tooltip to display information.
     < x: The text to be evaluated. */
  tooLong(x: string): boolean {
    return (x.length > 12);
  }

  /* boolean viewOverflown(0):
     Sets "overflew" to true if the horizontal table view has overflow-x as active. */
  viewOverflown(): void {
    this.cdr.detectChanges();
    const table = this.hrTable.nativeElement;
    this.overflown = table.scrollWidth > table.clientWidth;
  }


  /* async void requestQQ(0):
     Requests the Quick Quotes. */
  async requestQQ(): Promise<void> {
    let DPC: string, globalObject: any, OPC: string;
    this.inProgress = true;
    
    // Clean data 
    this.quotesh = { carriers: [], costs: [], servicetypes: [] };
    this.quotesv = [];

    // Extract postcodes
    OPC = this.qqForm.controls.originPC.value.toString();
    DPC = this.qqForm.controls.destPC.value.toString();

    // Get first possible data from postcodes
    await this.selectLocations(OPC, DPC);

    // Create dummy global object for quotes with extracted data
    globalObject = {
      destination: {
        ...this.destAddress,
        destination: 1
      },
      isEcommerce: false,
      orderNumber: undefined,
      origin: {
        ...this.originAddress,
        origin: 1
      },
      packages: [{
        content: 'Feathers',
        height: this.qqForm.controls.height.value,
        length: this.qqForm.controls.length.value,
        shipmentType: 'box',
        weight: this.qqForm.controls.weight.value,
        width: this.qqForm.controls.width.value
      }],
      selected_rate: '',
      shipmentId: ''
    };
   
    // Get quotes
    this.user.getRates(globalObject).subscribe((x: any) => {
      if (x.status.code == 'success') {
        for (const y of x.data) {
          this.quotesh.carriers.push(y.carrier);
          this.quotesh.servicetypes.push(y.service);
          this.quotesh.costs.push(this.toCurrency(y.total_price));
          this.quotesv.push({
            carrier: y.carrier, cost: this.toCurrency(y.total_price), servicetype: y.service
          });
      }}

      if (this.quotesv.length > 0)
        this.requestSuccessful = true;
      this.requestGenerated = true;
      this.inProgress = false;
      this.viewOverflown();
    });
  }

  /* async void selectLocations(2):
     Sets indifferent locations that use the given postcode to send the dummy quote request.
     < origin: The origin's post code.
     < destination: The destination's post code. */
  async selectLocations(origin: string, destination: string): Promise<void> {
    // Validate origin and destination post codes
    if (origin.length !== 5) throw new Error("Invalid origin post code.");
    if (destination.length !== 5) throw new Error("Invalid destination post code.");

    try {
      // Convert Observables to Promises
      const originData: any = await firstValueFrom(this.user.getPcData(origin));
      const destinationData: any = await firstValueFrom(this.user.getPcData(destination));

      // Process origin data
      if (originData.message !== "Not found") {
        this.originAddress.postalCode = origin;
        this.originAddress.suburb = originData.data.neighborhoods[Math.floor(Math.random() * originData.data.neighborhoods.length)];
        this.originAddress.city = originData.data.city;
        this.originAddress.state = originData.data.state;
      }

      // Process destination data
      if (destinationData.message !== "Not found") {
        this.destAddress.postalCode = destination;
        this.destAddress.suburb = destinationData.data.neighborhoods[Math.floor(Math.random() * destinationData.data.neighborhoods.length)];
        this.destAddress.city = destinationData.data.city;
        this.destAddress.state = destinationData.data.state;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }


  /* private void buildAddressObj(0):
     Declares both address objects used for the procedure. */
  private buildAddressObj(): void {
    this.originAddress = {
      city: '', company: 'Búho', country: 'MX', email: 'default@origin.com', name: 'Buho Test', number: '77',
      phone: '5501234567', postalCode: '', reference: '', rfc: 'BUHO010100WNG', state: '', street: 'Buho', suburb: ''
    };
    this.destAddress = {
      city: '', company: 'Búho', country: 'MX', email: 'default@destination.com', name: 'Buho Test', number: '07',
      phone: '5576543210', postalCode: '', reference: '', rfc: 'BUHO010100WNG', state: '', street: 'Buho', suburb: ''
    };
  }

  /* private ValidationErrors|null PostCodeValidator(1):
     Approximately confirms the field contains a valid postcode.
     < control: The field's reference.
     > assess: The validation result. */
  private PostCodeValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value as string;
    let assess: ValidationErrors | null = null;
    
    // Check if value has appropriate length:
    if (value.length !== 5) {
      assess = { lengthError: 'Input must be exactly five characters long.' };
      return assess;
    }
    
    // Check if it is a valid post code:
    const prefix: number = Number(value.slice(0, 3)), suffix: number = Number(value.slice(3));

    if (prefix < 10 || (prefix === 169 && suffix !== 0) || (prefix > 169 && prefix < 200) || prefix === 440) // Unused post code prefixes
      assess = { notValid: 'Input is not a valid post code.' };
    else if (suffix > 42) {
      const srp: Array<number> = [579, 899, 359, 289, 909, 979, 499, 859, 629, 639, 679, 829,
        209, 229, 239, 279, 309, 339, 389, 419, 759, 779, 439, 619, 719, 769, 799, 869, 969, 999]; // Not-completely-used prefixes
      
      if (prefix === 249 || (suffix === 99 && srp.includes(prefix))
        || (suffix > 97 && srp.slice(0, 22).includes(prefix))
        || (suffix > 96 && srp.slice(0, 12).includes(prefix))
        || (suffix > 94 && srp.slice(0, 8).includes(prefix))
        || (suffix > 90 && srp.slice(0, 6).includes(prefix))
        || (suffix > 89 && srp.slice(0, 4).includes(prefix))
        || (suffix > 87 && srp.slice(0, 3).includes(prefix))
        || (suffix > 70 && (prefix === 579 || prefix === 899))
        || (suffix > 50 &&  prefix === 579))
        assess = { notValid: 'Input is not a valid post code.' };
    }
    
    return assess;
  }

  /* private string toCurrency(1):
     Receives a number and formats it as a currency string.
     < x: The given number */
  private toCurrency(x: number): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2,
    }).format(x);
  }
}