<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Integra tu Ecommerce</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><mat-icon>storefront</mat-icon></a></li>
                    <li class="breadcrumb-item">Configuración de Ecommerce</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-10 col-md-8 col-lg-6 text-center">
          <img src="assets/images/shopify.png" alt="Integra tu Ecommerce" class="img-fluid my-4">
          <p>Descubre cómo nuestra app de Shopify puede ayudar a optimizar y potenciar tu ecommerce. ¡Instálala ahora y empieza a disfrutar de todos los beneficios!</p>
          <a href="https://apps.shopify.com/buho-wing" class="btn btn-primary btn-lg mt-3">Instalar App en Shopify</a>
          <!-- <a href="https://apps.shopify.com/buho-wing" class="btn btn-primary btn-lg mt-3">Instalar App en Shopify</a> -->
        </div>
    </div>
</div>