<div style="display: flex; justify-content: flex-end;">
  <button type="button" class="btn-close" [mat-dialog-close]=""></button>
</div>
<div class="container-fluid" *ngIf="!loading; else loadingbar">
  <div *ngIf="receipt; else note">
    <h1 mat-dialog-title class="m-0">Pedido #{{ receipt.trackingNumber }}</h1>
    <hr />
    <div class="row">
      <div class="col-md-6 col-12 mb-3">
        <mat-card class="mat-elevation-z4">
          <mat-card-header>
            <mat-card-title class="mt-4">Detalles de recolección</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-list>
              <mat-list-item>
                <span>Recolectado en: {{ receipt.textRegDate }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon>person</mat-icon>
                <span class="receipt-element"> {{ receipt.name }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon>phone</mat-icon>
                <span class="receipt-element"> {{ receipt.phone || 'No registrado' }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon>email</mat-icon>
                <span class="receipt-element"> {{ receipt.email || 'No registrado' }}</span>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-6 col-12 mb-3">
        <div class="receipt-suptext">
          <mat-icon>aspect_ratio</mat-icon> <span class="receipt-element">Evidencia:</span>
        </div>
        <div class="image-container">
          <img [src]="receipt.photoURL" alt="Evidencia">
        </div>
      </div>
    </div>
  </div>
  <ng-template #note>
    <div class="note-block my-4">
      <p class="note">Ocurrió un error recolectando la información. Por favor intenta más tarde.</p>
    </div>
  </ng-template>
</div>
<ng-template #loadingbar>
  <div class="my-5">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>